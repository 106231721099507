import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormGroup } from '@angular/forms';
import { AppService } from '@src/app/app.service';
import { PassholderTypeFilterComponent } from '@src/app/components/passholder-type-filter/passholder-type-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CustomTypeColumn } from '@src/app/models/zpx-api.model';
import { CustomColumnDialogComponent } from '@src/app/components/modals/import-modal/custom-column-dialog/custom-column-dialog.component';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { ImportPreviewComponent } from '@src/app/components/modals/import-modal/import-preview/import-preview.component';
import { ImportPassholdersService } from '@services/import-passholders/import-passholders.service';
import { concat } from 'rxjs';
import _ from 'lodash-es';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-import-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    PassholderTypeFilterComponent,
    DragDropModule,
    CustomColumnDialogComponent,
    ImportPreviewComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit, AfterViewInit {
  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private zpxApiService: ZpxApiService,
    public importPassholdersService: ImportPassholdersService
  ) {}

  data = inject<any>(MAT_DIALOG_DATA);
  formGroup = new FormGroup({});
  typeDefaultOption$ = this.appService.passholderTypeId$;
  customColumnsChanged = false;
  loadingCustomColumns: boolean;
  deleteIconClass = {
    'slightly-bigger': false
  };
  downloadCsvLinkStyles = {
    'text-decoration': 'underline',
    cursor: 'pointer'
  };

  editableCustomColumns: CustomTypeColumn[] = [];

  reorderCustomColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.editableCustomColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.checkCustomColumnsChanged();
  }

  checkCustomColumnsChanged() {
    if (
      _.isEqual(
        this.importPassholdersService.pristineCustomColumns,
        this.editableCustomColumns
      )
    ) {
      this.customColumnsChanged = false;
    } else {
      this.customColumnsChanged = true;
    }
  }

  removeCustomColumn(event: CdkDragDrop<string[]>) {
    this.editableCustomColumns.splice(event.previousIndex, 1);
    this.decreaseDeleteIconSize();
    this.checkCustomColumnsChanged();
  }

  increaseDeleteIconSize() {
    this.deleteIconClass['slightly-bigger'] = true;
  }
  decreaseDeleteIconSize() {
    this.deleteIconClass['slightly-bigger'] = false;
  }

  downloadCSV(type: 'example' | 'complete') {
    if (type == 'example') {
      this.importPassholdersService.generateExampleCsv();
    }
    if (type == 'complete') {
      this.importPassholdersService.downloadCompleteCsv();
    }
  }

  openCustomColumnDialog(
    action: 'edit' | 'add',
    customColumn: CustomTypeColumn = undefined
  ) {
    this.dialog
      .open(CustomColumnDialogComponent, {
        data: {
          action: action,
          customCol: customColumn
        },
        disableClose: true
      })
      .afterClosed()
      .subscribe((data) => {
        this.handleCustomColumnDialogChange(data);
        this.checkCustomColumnsChanged();
      });
  }

  saveCustomColumns() {
    this.loadingCustomColumns = true;
    this._updateCustomColSequences();
    const [colsToDelete, colsToPatch, colsToPost] =
      this._determineCustColOperations();
    this._performCustColOperations(colsToDelete, colsToPatch, colsToPost);
  }

  _updateCustomColSequences() {
    // used to update the sequence values of the edited custom columns.
    // should be called as the first part of the logic to determine necessary cust col operations by the api
    this.editableCustomColumns.forEach((v, i, a) => (a[i].sequence = i + 1));
  }

  _determineCustColOperations() {
    const custColsToDelete: CustomTypeColumn[] | [] =
      this.importPassholdersService.pristineCustomColumns.filter((obj1) => {
        return !this.editableCustomColumns.some((obj2) => obj1.id === obj2.id);
      });

    const custColsToPatch: CustomTypeColumn[] | [] = _.differenceWith(
      this.editableCustomColumns,
      this.importPassholdersService.pristineCustomColumns,
      _.isEqual
    ).filter((c) => c.id !== undefined); // custom columns without ids are added in the ui

    const custColsToPost: { name: string; sequence: number }[] | [] =
      this.editableCustomColumns.filter((c) => c.id === undefined);

    return [custColsToDelete, custColsToPatch, custColsToPost];
  }

  _performCustColOperations(colsToDelete?, colsToPatch?, colsToPost?) {
    const deleteObs$ = colsToDelete.map((col: CustomTypeColumn) =>
      this.zpxApiService.deleteCustomColumn(col.id).pipe(take(1))
    );

    const patchObs$ = colsToPatch.map((col: CustomTypeColumn) => {
      const body = _.pick(col, ['name', 'sequence']);
      return this.zpxApiService.patchCustomColumn(body, col.id).pipe(take(1));
    });
    const passholderTypeId = this.importPassholdersService.passholderTypeId;
    const postObs$ = colsToPost.map((col: Partial<CustomTypeColumn>) =>
      this.zpxApiService
        .postCustomColumn({
          passholder_type_id: passholderTypeId,
          ...col
        })
        .pipe(take(1))
    );
    // little bit of duplicated code. could refactor later
    const refreshStateAfterSaveObs$ = this.zpxApiService
      .getCustomColumns({
        refreshAcrossApp: true,
        passholderTypeId
      })
      .pipe(
        map((customCols) => {
          this.editableCustomColumns = _.cloneDeep(customCols);
          this.importPassholdersService.pristineCustomColumns =
            _.cloneDeep(customCols);
          this.checkCustomColumnsChanged();
        }),
        take(1)
      );
    // concat allows us to perform these requests sequentially, avoiding any potential custom column 'sequence' conflicts
    concat(
      ...[...deleteObs$, ...patchObs$, ...postObs$],
      refreshStateAfterSaveObs$
    )
      .pipe(
        tap(() => {
          this.loadingCustomColumns = false;
        })
      )
      .subscribe();
  }

  handleCustomColumnDialogChange(data) {
    if (data.action === 'edit') {
      this.editableCustomColumns[
        this.editableCustomColumns.findIndex((c) => c.id == data.customCol.id)
      ].name = data.newColName;
    }
    if (data.action === 'add') {
      const newCustCol = { name: data.newColName } as CustomTypeColumn;
      this.editableCustomColumns.push(newCustCol);
    }
  }

  csvInputChange(fileInputEvent: Event) {
    const element = fileInputEvent.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (fileList) {
      this.importPassholdersService.processImportCsv(fileList[0]);
    }
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        switchMap((changes) => {
          const pTypeId = changes['passholder_type_id'];
          if (pTypeId !== undefined) {
            this.editableCustomColumns = [];
            this.importPassholdersService.passholderTypeId = pTypeId;
            this.loadingCustomColumns = true;
            return this.zpxApiService.getCustomColumns({
              refreshAcrossApp: false,
              passholderTypeId: pTypeId
            });
          }
        }),
        map((customCols: CustomTypeColumn[]) => {
          this.loadingCustomColumns = false;
          this.editableCustomColumns = _.cloneDeep(customCols);
          this.importPassholdersService.pristineCustomColumns =
            _.cloneDeep(customCols);
          this.checkCustomColumnsChanged();
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // this is a really annoying hack because the pattern library SearchableDropdownComponent adds its own
    // control even when you try very hard for it to not.
    // https://gitlab.com/ZonarSystems/pattern-library/zonar-ui-searchable-dropdown/-/blob/main/projects/zonar-ui-searchable-dropdown/README.md
    // search 'searchFormControlName'
    if (this.formGroup.contains('undefined')) {
      this.formGroup.removeControl('undefined');
    }
  }
}
