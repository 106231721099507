import { Injectable } from '@angular/core';
import {
  CustomTypeColumn,
  ZpxApiValidatePassholderCSVResponse
} from '@src/app/models/zpx-api.model';
import { Papa, ParseResult } from 'ngx-papaparse';
import { BehaviorSubject, of } from 'rxjs';
import _ from 'lodash-es';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { take, tap } from 'rxjs/operators';
import { downloadCsv, formatDate } from '@app/shared/utilities/utilities';
import {
  ValidationError,
  ValidationState
} from '@components/modals/import-modal/import-passholders.model';

@Injectable({
  providedIn: 'root'
})
export class ImportPassholdersService {
  constructor(
    private papa: Papa,
    private zpxApiService: ZpxApiService
  ) {}
  public passholderTypeId: string;
  public standardColumns = [
    'Card No',
    'Status',
    'Last Name',
    'First Name',
    'Unique ID',
    'Group Name'
  ];
  public importData: string[][];

  frontendValidationState: BehaviorSubject<ValidationState> =
    new BehaviorSubject(undefined);
  backendValidationState: BehaviorSubject<ValidationState> =
    new BehaviorSubject(undefined);

  validationComplete = false;
  validationInProgress: boolean;
  commitComplete = false;
  pristineCustomColumns: CustomTypeColumn[] = [];

  getExpectedColumnHeaders(): string[] {
    return this.standardColumns.concat(
      this.pristineCustomColumns.map((cc) => cc.name)
    );
  }

  resetValidationState() {
    this.frontendValidationState.next(undefined);
    this.backendValidationState.next(undefined);
    this.validationComplete = false;
    this.commitComplete = false;
    this.importData = undefined;
  }

  processImportCsv(file: File) {
    this.validationInProgress = true;
    const options = {
      complete: (results: ParseResult) => {
        const feValidationState = this.performFrontendValidation(results);
        this.frontendValidationState.next(feValidationState);

        if (feValidationState.valid) {
          // store parsed import file in order to display added passholder info
          this.importData = results.data;
          this.performBackendValidation(file);
        } else {
          this.validationInProgress = false;
          this.validationComplete = true;
        }
      }
    };
    this.papa.parse(file, options);
  }
  generateExampleCsv() {
    downloadCsv(
      this.papa.unparse([this.getExpectedColumnHeaders()]),
      'example.csv'
    );
  }

  downloadCompleteCsv() {
    this.zpxApiService
      .getCompleteCsv(this.passholderTypeId)
      .pipe(take(1))
      .subscribe((resp) => {
        const fileName = `complete-${formatDate(new Date())}.csv`;
        if (resp.status === 200) {
          downloadCsv(resp.body, fileName);
        }
        if (resp.status === 204) {
          // if no content, just download the headers csv
          downloadCsv(
            this.papa.unparse([this.getExpectedColumnHeaders()]),
            fileName
          );
        }
      });
  }

  performFrontendValidation(parsedCsvResult: ParseResult) {
    const { data, errors } = parsedCsvResult;
    let validationState = new ValidationState();
    if (errors.length) {
      validationState.errors.push(
        new ValidationError(
          'Malformed CSV',
          'CSV must include column headers and passholder rows',
          0
        )
      );
      return validationState;
    }
    if (data.length === 1) {
      validationState.errors.push(
        new ValidationError(
          'Missing Passholders',
          'Import CSV must include passholder rows',
          2
        )
      );
      return validationState;
    } else {
      const csvHeaders: string[] = data[0];
      const expectedHeaders: string[] = this.getExpectedColumnHeaders();
      const missingHeaders: string[] = _.difference(
        expectedHeaders,
        csvHeaders
      );
      const extraHeaders = _.difference(csvHeaders, expectedHeaders);
      if (missingHeaders.length) {
        validationState.errors = validationState.errors.concat(
          missingHeaders.map(
            (missingHeader) =>
              new ValidationError('Missing Column', missingHeader, 1)
          )
        );
      }
      if (extraHeaders.length) {
        validationState.errors = validationState.errors.concat(
          extraHeaders.map(
            (extraHeader) => new ValidationError('Extra Column', extraHeader, 1)
          )
        );
      }
    }

    return validationState;
  }

  performBackendValidation(file: File) {
    // this doesn't need to get called unless the file import passes frontend validation
    this.zpxApiService
      .validatePassholderImport(file, this.passholderTypeId)
      .subscribe((resp) => {
        this.backendValidationState.next(
          this._parseBackendValidationResponse(resp)
        );
        this.validationInProgress = false;
        this.validationComplete = true;
      });
  }

  _parseBackendValidationResponse(
    resp: ZpxApiValidatePassholderCSVResponse
  ): ValidationState {
    let validationState = new ValidationState();
    const validationErrors = resp.row_results
      .filter((rr) => rr['row_result'] === 'validation_error')
      .map((ve) => {
        return JSON.parse(ve.validation_error).map((valErrorDetail) => {
          return new ValidationError(
            valErrorDetail.loc[0],
            valErrorDetail.msg,
            ve.index + 2
          );
        });
      })
      .flat();
    if (validationErrors.length > 0) {
      validationState.errors = validationErrors;
      return validationState;
    }
    const importErrors = resp.row_results
      .filter((rr) => rr['row_result'] === 'import_error')
      .map((errorRow) => {
        return new ValidationError(
          'Import Error',
          errorRow.import_error,
          errorRow.index + 2 // to account for headers and zero index row_results
        );
      });
    if (importErrors.length > 0) {
      validationState.errors = importErrors;
      return validationState;
    }
    if (resp.success) {
      validationState.validationId = resp.id; // used in follow-up "commit" action
      validationState.rowResults = resp.row_results;
      validationState.importHeaders = this.importData[0];
      validationState.importData = this.importData.slice(1);
    }

    return validationState;
  }

  commitImportedPassholders(validationId: string) {
    return this.zpxApiService.commitPassholderImport(validationId).pipe(
      tap(() => {
        this.commitComplete = true;
      })
    );
  }
}
