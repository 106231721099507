import { Injectable } from '@angular/core';
import { ZpxApiService } from '../zpx-api-service/zpx-api.service';
import {
  STATUSES,
  ZpxApiPassholderParams
} from '@src/app/models/zpx-api.model';
import { catchError, map } from 'rxjs/operators';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import {
  getMostRecentPass,
  formatDate
} from '@src/app/shared/utilities/utilities';
import { DataDogService } from '../data-dog/data-dog.service';
import { throwError } from 'rxjs';
import { AngularCSVOptions, HEADER_LABELS } from './export-passholders.model';

@Injectable({
  providedIn: 'root'
})
export class ExportPassholdersService {
  constructor(
    private zpxApiService: ZpxApiService,
    private datadogService: DataDogService
  ) {}

  generateCsv(data: any, csvName: string, options: AngularCSVOptions) {
    return new AngularCsv(data, csvName, options);
  }

  exportCsv(zpxApiParams: ZpxApiPassholderParams) {
    return this.zpxApiService.getPassholders(zpxApiParams).pipe(
      map((response) => {
        if (!response.data.length) {
          return;
        }
        const csvPassholders = [];
        response.data.forEach((p) => {
          let passes = null;
          if (p.passes) {
            passes = Object.keys(p.passes).map((key) => {
              return {
                ...p.passes[key],
                insert_ts: new Date(p.passes[key].insert_ts)
              };
            });
          }

          const mostRecentPass = getMostRecentPass(passes);
          const currentCardNumber = mostRecentPass?.number;
          let passholder = {
            [HEADER_LABELS.CARD_NO]: mostRecentPass?.number,
            [HEADER_LABELS.STATUS]: p.active
              ? STATUSES.ACTIVE
              : STATUSES.INACTIVE,
            [HEADER_LABELS.LAST_NAME]: p.last_name,
            [HEADER_LABELS.FIRST_NAME]: p.first_name,
            [HEADER_LABELS.UNIQUE_ID]: p.exsid,
            [HEADER_LABELS.RECEIVED_DATE]:
              formatDate(mostRecentPass?.insert_ts) || null
          };

          csvPassholders.push(passholder);

          if (passes?.length > 1) {
            passes.forEach((innerPass) => {
              if (innerPass.number !== currentCardNumber) {
                passholder = {
                  ...passholder,
                  [HEADER_LABELS.CARD_NO]: innerPass.number,
                  [HEADER_LABELS.STATUS]: innerPass.active
                    ? STATUSES.ACTIVE
                    : STATUSES.INACTIVE,
                  [HEADER_LABELS.RECEIVED_DATE]:
                    formatDate(innerPass?.insert_ts) || null
                };
                csvPassholders.push(passholder);
              }
            });
          }
        });
        if (csvPassholders.length) {
          const csvName = `zpass-card-history${formatDate(new Date())}`;
          return this.generateCsv(csvPassholders, csvName, {
            headers: Object.keys(csvPassholders[0]),
            showLabels: true
          });
        }
      }),
      catchError((error) => {
        this.datadogService.addRumError(error);
        return throwError(error);
      })
    );
  }
}
