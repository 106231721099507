<mat-dialog-content *ngIf="data.action === 'edit'" class="cardholder-modal">
  <h2 mat-dialog-title>Edit Cardholder</h2>
  <mat-tab-group>
    <mat-tab label="Details" fxFlex>
      <app-passholder-filter-bar
        (sendPatchBody)="getPatchBody($event)"
        (formStatus)="formStatus($event)"
        [passholder]="data.passholder"
        [action]="data.action"></app-passholder-filter-bar>
      <app-passholder-events-table> </app-passholder-events-table>
      <a class="move-right">View more events</a>
    </mat-tab>

    <mat-tab label="History">
      <app-passholder-history-table
        [passholder]="data.passholder"></app-passholder-history-table>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-content *ngIf="data.action === 'add'" class="cardholder-modal">
  <h2 mat-dialog-title>Add Cardholder</h2>
  <app-passholder-filter-bar
    (sendPostBody)="getPostBody($event)"
    (formStatus)="formStatus($event)"
    [action]="data.action"></app-passholder-filter-bar>
</mat-dialog-content>

<mat-dialog-actions>
  <div [ngClass]="data.action === 'edit' ? 'left-align': ''">
    <button
      class="mat-stroked-button mat-button-base accent-button"
      (click)="performPassholderAction()">
      Save
    </button>
  </div>
  <button
    class="mat-stroked-button mat-button-base white-button"
    (click)="dialogRef.close()">
    Cancel
  </button>
  <div *ngIf="data.action === 'edit'" class="right-align">
    <mat-label class="right-align">
      Find unassigned events starting from:</mat-label
    >
    <zui-date-picker class="date-picker" label="Date"> </zui-date-picker>

    <button
      (click)="filterEvents()"
      class="mat-stroked-button mat-button-base accent-button right-align">
      Search
    </button>
  </div>
</mat-dialog-actions>
