<div id="add-group-modal">
  <mat-dialog-content>
    <h3>Add New Group</h3>

    <app-zpx-input
      label="Group Name"
      zpxInputName="groupName"
      [control]="groupNameFormControl"
      [formGroup]="formGroup"></app-zpx-input>

    <app-status-filter
      label="Group Status"
      [formGroup]="formGroup"
      defaultOption="1">
    </app-status-filter>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="button-container">
      <button
        (click)="dialogRef.close()"
        class="mat-stroked-button mat-button-base white-button">
        Cancel
      </button>
      <button
        (click)="addGroup()"
        class="mat-stroked-button mat-button-base accent-button">
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
