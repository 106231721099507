import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
  MatDialog
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { ZpxInputComponent } from '../../zpx-input/zpx-input.component';
import { StatusFilterComponent } from '../../status-filter/status-filter.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppService } from '@src/app/app.service';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  throwError
} from 'rxjs';
import {
  Group,
  GroupBody,
  ZpxGroupDivision,
  ZpxGroupDivisionExtended
} from '@src/app/models/zpx-api.model';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { LegacyLocation } from '@src/app/models/entity-api.model';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { AddGroupModalComponent } from '@src/app/components/modals/manage-group-modal/add-group-modal/add-group-modal.component';

@Component({
  selector: 'app-manage-group-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    FlexModule,
    FlexLayoutModule,
    ZpxInputComponent,
    StatusFilterComponent,
    MatCheckboxModule
  ],
  templateUrl: './manage-group-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ManageGroupModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ManageGroupModalComponent>,
    private dialog: MatDialog,
    private appService: AppService,
    private zpxService: ZpxApiService,
    public selectedCompanyService: SelectedCompanyService
  ) {}

  data = inject<any>(MAT_DIALOG_DATA);
  formGroup = new FormGroup({});
  groupInputFormControl = new FormControl('');
  groups: Group[] = null;
  legacyLocations$: BehaviorSubject<LegacyLocation[]> =
    this.appService.legacyLocations$;

  legacyLocations: LegacyLocation[] = [];

  selectedGroup: Group = null;

  groupPatchBody: GroupBody = {
    name: null,
    active: null
  };

  groupDivisionPostBody: ZpxGroupDivision[] = [];
  groupDivisionPostBody$: BehaviorSubject<ZpxGroupDivision[]> =
    new BehaviorSubject([]);
  groupDivisionDeleteBody: string[] = [];
  groupDivisionDeleteBody$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  groupDivisions: ZpxGroupDivisionExtended[];

  showInactiveGroups = new BehaviorSubject(false);
  groups$: BehaviorSubject<Group[]> = this.appService.groupsByNameAsc$;
  isDuplicateGroupName$ = new BehaviorSubject(null);

  onGroupClick$ = new BehaviorSubject(null);

  private onDestroy$ = new Subject();

  ngOnInit(): void {
    this.onGroupClick$.next(null);
    this.appService.groupsByNameAsc$
      .pipe(
        filter((groups) => groups !== null),
        switchMap((groups) => {
          return this.appService.selectedGroup$.pipe(
            map((selectedGroup) => {
              // we may have as selected group already if a user has added a new group, so this accounts for that case to change the group selection after new group post
              if (selectedGroup) {
                groups[0].isClicked = false;
                this.selectedGroup = selectedGroup;
                this.groupInputFormControl.setValue(selectedGroup.name);
              } else if (selectedGroup === null) {
                // if we are here, it's the initial opening of the manage groups modal, and we default to selecting the first item
                const firstGroup = groups[0];
                this.selectedGroup = firstGroup;
                this.groupInputFormControl.setValue(firstGroup.name);
                firstGroup.isClicked = true;
                this.getGroupDivisions(firstGroup);
              }
              return groups;
            })
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((groups) => {
        this.groups = groups;
      });

    this.legacyLocations$
      .pipe(
        filter((legacyLocations) => legacyLocations !== null),
        takeUntil(this.onDestroy$)
      )
      .subscribe((legacyLocations) => {
        this.legacyLocations = legacyLocations;
      });

    this.getGroupFormChanges();
  }

  filteredGroups$ = combineLatest([this.groups$, this.showInactiveGroups]).pipe(
    map(([groups, showActiveOnly]) =>
      showActiveOnly ? groups : groups?.filter((group) => group.active)
    )
  );

  toggleActiveGroupOnly(checked: boolean) {
    this.showInactiveGroups.next(checked);
  }

  getGroupFormChanges() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes) => {
        const name = changes['group'];
        const active = changes['active'];

        this.groupPatchBody.name = name !== null ? name : null;
        this.groupPatchBody.active =
          active !== null ? Boolean(parseInt(active)) : null;
      });
  }

  ngOnDestroy(): void {
    // ternaries added below since testing would have errors and couldn't figure out how to mock things in the ngOnDestroy stage

    // on manage groups modal close, we want to fetch a new batch of groups that will populate elsewhere in app and be updated when reopening IF a user created a new group
    if (this.appService?.selectedGroup$?.value) {
      this.zpxService?.getGroups()?.pipe(first()).subscribe();
    }

    this.groups.forEach((g) => {
      g.isClicked = false;
    });

    // we send this off so that a previously created new group is no longer in a selected state on reopening modal
    this.appService?.selectedGroup$?.next(null);
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.legacyLocations = [];
  }

  getGroupDivisions(group) {
    this.zpxService
      .getGroupDivisions(group.id)
      .pipe(first())
      .subscribe((groupDivisions) => {
        this.groupDivisions = groupDivisions;

        // upon opening modal, the first group is always selected, so we need to get their legacy locations as well on opening modal
        // this is different from the checkmark click handler since we need to do it automatically, see onLegacyLocationCheckboxChange method for how that logic works
        this.checkLegacyLocationBySelectedGroup();
      });
  }

  resetGroupDivisionBodies() {
    this.groupDivisionPostBody = [];
    this.groupDivisionDeleteBody = [];

    this.groupDivisionPostBody$.next(this.groupDivisionPostBody);
    this.groupDivisionDeleteBody$.next(this.groupDivisionDeleteBody);
  }

  // check/uncheck the legacy locations on right hand side based of selected group
  checkLegacyLocationBySelectedGroup() {
    const legacyLocIdsFromGroup = this.groupDivisions.map(
      (g) => g.legacy_location_division_id
    );

    const updatedLocs = this.legacyLocations.map((loc) => {
      if (legacyLocIdsFromGroup.includes(loc.id)) {
        const copy = {
          ...loc,
          isChecked: true
        };
        return copy;
      }
      const copy = {
        ...loc,
        isChecked: false
      };
      return copy;
    });

    this.legacyLocations = updatedLocs;
  }

  getGroupDivisionsOnClick(group: Group) {
    this.resetGroupDivisionBodies();
    this.onGroupClick$.next(null);
    this.onGroupClick$
      .pipe(
        first(),
        switchMap(() => {
          return this.zpxService.getGroupDivisions(group.id);
        })
      )
      .subscribe((groupDivisions) => {
        this.groupDivisions = groupDivisions;

        this.checkLegacyLocationBySelectedGroup();
      });
  }

  setGroupStatusForm(groupIsActive: boolean) {
    const statusForm = this.formGroup.controls['active'];
    const active = groupIsActive ? '1' : '0';
    statusForm.setValue(active);
  }

  onGroupClick(group: Group) {
    this.getGroupDivisionsOnClick(group);
    this.groupInputFormControl.setValue(group.name);
    group.isClicked = true;
    this.selectedGroup = group;
    this.setGroupStatusForm(group.active);

    this.groups.forEach((g) => {
      if (g.id !== group.id) {
        g.isClicked = false;
      }
    });
  }

  checkIsDuplicateGroupName() {
    let isDupe = false;
    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i].name === this.groupPatchBody.name) {
        // allows "patching" with the same name in case a user is just patching active/inactive state of selected group
        if (this.groups[i].id === this.selectedGroup.id) {
          break;
        }
        isDupe = true;
        break;
      }
    }

    this.isDuplicateGroupName$.next(isDupe);
  }

  performGroupActions() {
    const postAndDeleteGroupDivs$ = combineLatest([
      this.postGroupDivisions(),
      this.deleteGroupDivisions()
    ]);

    postAndDeleteGroupDivs$.subscribe(
      (response) => {
        return response;
      },
      (error) => {
        console.error(error);
      }
    );

    this.patchGroup().subscribe(
      (_) => {
        this.dialogRef.close();
      },
      (error) => {
        this.dialogRef.close();
        console.error(error);
      }
    );
  }

  deleteGroupDivisions(): Observable<any> {
    return this.groupDivisionDeleteBody$.pipe(
      filter((divisionBody) => Boolean(divisionBody.length)),
      mergeMap((divisionBody) => {
        return this.zpxService.deleteGroupDivisions(divisionBody);
      }),
      catchError((error) => throwError(error)),
      takeUntil(this.onDestroy$)
    );
  }

  postGroupDivisions(): Observable<any> {
    return this.groupDivisionPostBody$.pipe(
      filter((divisionBody) => Boolean(divisionBody.length)),
      mergeMap((divisionBody) => {
        return this.zpxService.postGroupDivisions(divisionBody);
      }),
      catchError((error) => throwError(error)),
      takeUntil(this.onDestroy$)
    );
  }

  patchGroup(): Observable<any> {
    this.checkIsDuplicateGroupName();

    return this.isDuplicateGroupName$.pipe(
      filter((isDupe) => isDupe === false),
      mergeMap((_) => {
        return this.zpxService
          .patchGroup(this.selectedGroup.id, this.groupPatchBody)
          .pipe(takeUntil(this.onDestroy$));
      }),
      tap((response) => {
        const updatedGroups = this.groups.map((g) => {
          if (g.id === response.id) {
            g = response;
          }
          return g;
        });

        this.appService.groupsByNameAsc$.next(updatedGroups);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  setGroupDivisionDeleteBody(location: LegacyLocation) {
    const groupLegacyLocationAsscId = this.groupDivisions
      .filter((g) => {
        return g.legacy_location_division_id === location.id;
      })
      .map((g) => g.id);

    this.groupDivisionDeleteBody = this.groupDivisionDeleteBody.concat(
      groupLegacyLocationAsscId
    );

    this.groupDivisionDeleteBody$.next(this.groupDivisionDeleteBody);
  }

  onLegacyLocationCheckboxChange(checked: boolean, location: LegacyLocation) {
    if (checked) {
      this.groupDivisionPostBody.push({
        zpx_group_id: this.selectedGroup.id,
        legacy_location_division_id: location.id
      });
    } else {
      this.groupDivisionPostBody = this.groupDivisionPostBody.filter(
        (item) => item.legacy_location_division_id !== location.id
      );
      this.setGroupDivisionDeleteBody(location);
    }
    this.groupDivisionPostBody$.next(this.groupDivisionPostBody);
  }

  openAddGroupModal() {
    this.dialog.open(AddGroupModalComponent, {
      width: '360px'
    });
  }
}
