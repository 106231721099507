import { ZpxApiValidatePassholderCsvResponseRowResult } from '@src/app/models/zpx-api.model';

export class ValidationError {
  errorType: string;
  errorDetail: string;
  csvRow: number;
  constructor(errorType: string, errorDetail: string, csvRow: number) {
    this.errorType = errorType;
    this.errorDetail = errorDetail;
    this.csvRow = csvRow;
  }
}

export class ValidationState {
  validationId?: string;
  errors: ValidationError[];
  rowResults?: ZpxApiValidatePassholderCsvResponseRowResult[];
  importHeaders?: string[];
  importData?: string[][];
  constructor() {
    this.errors = [];
  }
  get valid(): boolean {
    return this.errors.length === 0;
  }
  get updates(): ImportPassholderPreviewRecord[] {
    const results = [];
    for (const rr of this.rowResults) {
      if (rr['row_result'] === 'update') {
        const updateRecord = {};
        const row = this.importData[rr.index];

        // Manually populate the updateRecord with importHeaders and row data
        for (let j = 0; j < this.importHeaders.length; j++) {
          updateRecord[this.importHeaders[j]] = row[j];
        }

        // Add changedFields to the updateRecord
        updateRecord['changedFields'] = {};
        for (const cf of rr.changed_fields) {
          updateRecord['changedFields'][cf.column_name] = {
            old: cf.old,
            new: cf.new === '' ? 'Deleted' : cf.new
          };
        }

        results.push(updateRecord);
      }
    }
    return results;
  }
  get new(): ImportPassholderPreviewRecord[] {
    const result = [];
    for (const rr of this.rowResults) {
      if (rr['row_result'] === 'new') {
        const newRecord = {};
        const row = this.importData[rr.index];

        for (let j = 0; j < this.importHeaders.length; j++) {
          newRecord[this.importHeaders[j]] = row[j];
        }
        result.push(newRecord);
      }
    }
    return result;
  }
  get unchanged(): ImportPassholderPreviewRecord[] {
    const result = [];
    for (const rr of this.rowResults) {
      if (rr['row_result'] === 'no_change') {
        const unchangedRecord = {};
        const row = this.importData[rr.index];

        for (let j = 0; j < this.importHeaders.length; j++) {
          unchangedRecord[this.importHeaders[j]] = row[j];
        }
        result.push(unchangedRecord);
      }
    }
    return result;
  }
}

export enum PreviewType {
  UPDATE = 'update',
  NEW = 'new',
  UNCHANGED = 'unchanged'
}

export type ImportPassholderPreviewRecord = {
  'Card No': string;
  'First Name': string;
  'Group Name': string;
  'Last Name': string;
  Status: string;
  'Unique ID': string;
  changedFields?: {
    [key: string]: {
      // column name
      old: string;
      new: string;
    };
  };
} & { [key: string]: string }; // cust col name
