<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="csvRow">
    <th mat-header-cell *matHeaderCellDef>Row</th>
    <td mat-cell *matCellDef="let error">{{error.csvRow}}</td>
  </ng-container>

  <ng-container matColumnDef="errorType">
    <th mat-header-cell *matHeaderCellDef>Error Type</th>
    <td mat-cell *matCellDef="let error">{{error.errorType}}</td>
  </ng-container>

  <ng-container matColumnDef="errorDetail">
    <th mat-header-cell *matHeaderCellDef>Error Detail</th>
    <td mat-cell *matCellDef="let error">{{error.errorDetail}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator
  *ngIf="this.dataSource.data.length > 10"
  [pageSizeOptions]="[10, 20, 50, 100, 500]"
  showFirstLastButtons>
</mat-paginator>
