import { Component, forwardRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import { Passholder, PassholderPatchBody } from '@src/app/models/zpx-api.model';
import { PassholderFilterBarComponent } from '../../passholder-filter-bar/passholder-filter-bar.component';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { AppService } from '@src/app/app.service';
import { SelectedCompanyService } from '@services/selected-company/selected-company.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { FormControlStatus } from '@angular/forms';
import { PassholderEventsTableComponent } from '@components/passholder-events-table/passholder-events-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ZuiDatePickerModule } from '@zonar-ui/date-picker';
import { PassholderHistoryTableComponent } from '../../passholder-history-table/passholder-history-table.component';

@Component({
  selector: 'app-passholder-modal',
  standalone: true,

  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    FlexLayoutModule,
    PassholderFilterBarComponent,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ZuiDatePickerModule,
    PassholderEventsTableComponent,
    PassholderHistoryTableComponent
  ],
  templateUrl: './passholder-modal.component.html',
  styleUrls: ['./passholder-modal.component.scss']
})
export class PassholderModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PassholderModalComponent>,
    private zpxApiService: ZpxApiService,
    private dataDogService: DataDogService,
    private appService: AppService,
    private selectedCompanyService: SelectedCompanyService
  ) {}

  data = inject(MAT_DIALOG_DATA);

  passHolderEventSearchDate = '';
  patchBody: PassholderPatchBody;
  postBody;
  validForm = new BehaviorSubject(false);

  @ViewChild(PassholderFilterBarComponent)
  passholderFilterBar: PassholderFilterBarComponent;

  hasEditPermission$ = this.selectedCompanyService
    .getUserPermissions()
    .pipe(
      map((permissionValues: string[]) =>
        permissionValues.includes('zpass:edit:passholder')
      )
    );

  saveDisabled = combineLatest([this.validForm, this.hasEditPermission$]).pipe(
    distinctUntilChanged(),
    map((arr) => arr.some((v) => v !== true))
  );

  getPatchBody(body: PassholderPatchBody) {
    this.patchBody = body;
  }

  getPostBody(body) {
    this.postBody = body;
  }

  performPassholderAction() {
    if (this.validForm.value) {
      if (this.data.action == 'edit') {
        this._patchPassholder();
      }
      if (this.data.action == 'add') {
        this._postPassholder();
      }
    } else {
      this.passholderFilterBar.markAllAsTouched();
    }
  }

  formStatus(formStatus: FormControlStatus) {
    formStatus === 'VALID'
      ? this.validForm.next(true)
      : this.validForm.next(false);
  }

  _postPassholder() {
    combineLatest([
      this.selectedCompanyService.getCompanyId(),
      this.appService.selectedDivisionId$
    ])
      .pipe(
        switchMap(([companyId, divisionId]) => {
          this.postBody = {
            ...this.postBody,
            company_id: companyId,
            division_id: divisionId
          };
          return this.zpxApiService.postPassholder(this.postBody);
        })
      )
      .subscribe(
        () => {
          // TODO add similar functionality as the _patchPassholder response handler below
          // Piggybacking off the existing code doesnt quite work because the responses from POST
          // and PATCH are different
          this.dialogRef.close();
        },
        (error) => {
          this.dataDogService.addRumError(error);
          console.error(error);
        }
      );
  }

  _patchPassholder() {
    this.zpxApiService
      .patchPassholder(this.data.passholder.zpx_id, this.patchBody)
      .subscribe(
        (response) => {
          const patchedPassholder: Passholder = response;

          if (this.patchBody.passholder_type_id) {
            patchedPassholder.passholder_type_id =
              this.patchBody.passholder_type_id;
          }
          this.appService.patchedPassholder$.next(patchedPassholder);
          this.dialogRef.close();
        },
        (error) => {
          this.dataDogService.addRumError(error);
          console.error(error);
        }
      );
  }
  filterEvents() {
    //TODO Take the user to the filtered events page
  }
}
