<zui-table
  [title]="title"
  [columns]="columns"
  [dataSource]="dataSource"
  [enableMobileTable]="enableMobileTable"
  [initialPageSize]="initialPageSize"
  [enableColumnPicker]="false"
  [uniqueRowIdentifier]="uniqueRowIdentifier"
  (selectedRows)="select($event)"
  (rowClick)="click($event)">
  <ng-content></ng-content>
</zui-table>
