import { Component, inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { first } from 'rxjs/operators';
import { AppService } from '@src/app/app.service';

@Component({
  selector: 'app-confirm-deactivation-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './confirm-deactivation-modal.component.html',
  styleUrls: ['./confirm-deactivation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDeactivationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeactivationModalComponent>,
    private zpxApiService: ZpxApiService,
    private appService: AppService
  ) {}

  data = inject<{ selectedPassholderIds: string[] }>(MAT_DIALOG_DATA);

  setCardholderString(): string {
    return this.data.selectedPassholderIds.length === 1
      ? 'cardholder'
      : 'cardholders';
  }

  confirmDeactivation() {
    this.zpxApiService
      .deactivatePassholders(this.data.selectedPassholderIds)
      .pipe(first())
      .subscribe(
        () => {
          const newData = this.data.selectedPassholderIds.reduce(
            (acc, currentId) => {
              acc[currentId] = true;
              return acc;
            },
            {}
          );
          this.appService.deactivatedPassholders$.next(newData);
          this.dialogRef.close();
        },
        (error) => {
          this.dialogRef.close();
        }
      );
  }
}
