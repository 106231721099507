<table mat-table [dataSource]="dataSource">
  <ng-container
    [matColumnDef]="col"
    *ngFor="let col of displayedColumns; let i = index">
    <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
    <ng-container *ngIf="previewType !== 'update'">
      <td mat-cell *matCellDef="let cell">{{cell[col]}}</td>
    </ng-container>
    <ng-container *ngIf="previewType === 'update'">
      <td mat-cell *matCellDef="let cell">
        <ng-container *ngIf="cell['changedFields'][col]">
          <p class="changed-fields changed-fields-new-value">
            {{cell['changedFields'][col]['new']}}
          </p>
          <p class="changed-fields changed-fields-old-value">
            {{cell['changedFields'][col]['old']}}
          </p>
        </ng-container>
        <ng-container *ngIf="!cell['changedFields'][col]">
          {{cell[col]}}
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      {{noDataMessage[previewType]}}
    </td>
  </tr>
</table>

<mat-paginator
  *ngIf="this.dataSource.data.length > 10"
  [pageSizeOptions]="[10, 20, 50, 100, 500]"
  showFirstLastButtons>
</mat-paginator>
