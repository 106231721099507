<mat-dialog-content *ngIf="!validImport; else previewTabs">
  Oops! The following errors were found while trying to stage your import.
  Please correct the errors and reimport.
  <app-import-error-table [importErrors]="importErrors">
  </app-import-error-table>
</mat-dialog-content>

<ng-template #previewTabs>
  <mat-dialog-content *ngIf="!(this.importPassholdersService.commitComplete)">
    Review and Save Updates
  </mat-dialog-content>
  <mat-dialog-content *ngIf="this.importPassholdersService.commitComplete">
    Success! The following changes have been saved.
  </mat-dialog-content>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label> Updates {{updateCount}} </ng-template>
      <app-import-preview-table
        [validationState]="validationState"
        [previewType]="'update'">
      </app-import-preview-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> New {{newCount}} </ng-template>
      <app-import-preview-table
        [validationState]="validationState"
        [previewType]="'new'">
      </app-import-preview-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> No Change {{unchangedCount}} </ng-template>
      <app-import-preview-table
        [validationState]="validationState"
        [previewType]="'unchanged'">
      </app-import-preview-table>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<mat-dialog-actions [style]="'margin-top:auto; padding-bottom:1.5rem'">
  <div class="button-container" [style]="'width:100%;display:flex'">
    <ng-container *ngIf="!(this.importPassholdersService.commitComplete)">
      <button
        class="mat-stroked-button mat-button-base accent-button"
        (click)="goBack()"
        [style.margin-right]="'auto'">
        Back
      </button>
      <button
        class="mat-stroked-button mat-button-base white-button"
        mat-dialog-close>
        Cancel
      </button>
      <button
        *ngIf="validImport"
        class="mat-stroked-button mat-button-base accent-button"
        (click)="saveImport()"
        [disabled]="saveInProgress"
        [style]="'display:flex;align-items: center;justify-content:space-evenly'">
        <mat-spinner
          class="white"
          *ngIf="saveInProgress"
          diameter="20"></mat-spinner>
        <span *ngIf="!saveInProgress">Save</span>
      </button>
    </ng-container>
    <ng-container *ngIf="this.importPassholdersService.commitComplete">
      <button
        class="mat-stroked-button mat-button-base accent-button"
        mat-dialog-close
        [style.margin-left]="'auto'">
        Close
      </button>
    </ng-container>
  </div>
</mat-dialog-actions>
