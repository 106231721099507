<app-zpx-table
  (rowClick)="onClick($event)"
  class="passholders-zpx-table"
  title="Card Data"
  [dataSource]="dataSource"
  uniqueRowIdentifier="zpx_id"
  (selectedRows)="onSelect($event)"
  [columns]="columns$"
  [enableMobileTable]="enableMobileTable">
  <p>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button"
      (click)="openAddPassholder()">
      Add
    </button>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button"
      (click)="openManageGroupsDialog()">
      Manage Groups
    </button>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button"
      (click)="deactivateSelected()">
      Deactivate Selected
    </button>
    <button
      style="margin-right: 3px"
      class="mat-stroked-button mat-button-base white-button"
      (click)="openImportDialog()">
      Import
    </button>
    <button
      class="mat-stroked-button mat-button-base white-button export-button"
      (click)="exportAllPassholders()">
      {{ getExportButtonString() }}
    </button>
    <mat-icon style="margin-right: 3px" class="mat-accent"
      >help_outline</mat-icon
    >
  </p>
</app-zpx-table>
