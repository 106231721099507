import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  private _isMobile: boolean = false;
  private mediaSubscription!: Subscription;
  private activeMediaQuery: string = '';

  constructor(public mediaObserver: MediaObserver) {
    this._isMobile = this.mediaObserver.isActive('lt-md');
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.mediaSubscription = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        )
      )
      .subscribe((change) => {
        change.forEach((item) => {
          this.activeMediaQuery = item
            ? `'${item.mqAlias}' = (${item.mediaQuery})`
            : '';
        });
        this._isMobile = !['md', 'lg', 'xl'].includes(change[0].mqAlias);
      });
  }

  public get media(): MediaObserver {
    return this.mediaObserver;
  }

  public get isMobile(): boolean {
    return this._isMobile;
  }

  public unsubscribe(): void {
    this.mediaSubscription.unsubscribe();
  }
}
