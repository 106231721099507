<div
  ngClass.gt-sm="app-container-desktop"
  ngClass.lt-md="app-container-mobile"
  fxLayout="column"
  fxFill>
  <zui-sidenav
    [sidenavParams]="sidenavParams"
    [header]="sidenavHeader"
    (emitSelectedCompany)="switchCompany($event)">
    <div class="sidenav-container" fxFlex fxLayout="column">
      <zui-sidenav-header
        class="header-bar-sidenav"
        (menuToggled)="onSideNavMobileMenuButtonToggled($event)">
      </zui-sidenav-header>
      <div class="content-container" id="zpass-container" fxFlex>
        <router-outlet></router-outlet>
      </div>
      <zui-footer-component
        *ngIf="device.mediaObserver.isActive('gt-sm')"></zui-footer-component>
    </div>
  </zui-sidenav>
</div>
