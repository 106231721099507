export interface AngularCSVOptions {
  fieldSeparator?: string;
  quoteStrings?: string;
  decimalseparator?: string;
  showLabels?: boolean;
  showTitle?: boolean;
  title?: string;
  useBom?: boolean;
  noDownload?: boolean;
  headers: string[];
  useHeader?: boolean;
  nullToEmptyString?: boolean;
}

export enum HEADER_LABELS {
  CARD_NO = 'Card No',
  STATUS = 'Status',
  LAST_NAME = 'Last Name',
  FIRST_NAME = 'First Name',
  UNIQUE_ID = 'Unique ID',
  RECEIVED_DATE = 'Received Date',
  UNASSIGNED_DATE = 'Unassigned Date'
}
