import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { StatusFilterComponent } from '@src/app/components/status-filter/status-filter.component';
import { ZpxInputComponent } from '@src/app/components/zpx-input/zpx-input.component';
import { first, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { GroupBody } from '@src/app/models/zpx-api.model';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { AppService } from '@src/app/app.service';
@Component({
  selector: 'app-add-group-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    StatusFilterComponent,
    ZpxInputComponent
  ],
  templateUrl: './add-group-modal.component.html',
  styleUrls: ['./add-group-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddGroupModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<AddGroupModalComponent>,
    private zpxApiService: ZpxApiService,
    private appService: AppService
  ) {}

  formGroup = new FormGroup({});
  groupNameFormControl = new FormControl('');
  postBody: GroupBody = null;
  private onDestroy$ = new Subject<void>();

  ngOnInit(): void {
    this.getFormGroupChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFormGroupChanges() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes) => {
        if (this.postBody === null) {
          this.postBody = {} as any;
        }
        if (changes['groupName']) {
          this.postBody = {
            ...this.postBody,
            name: changes['groupName']
          };
        }
        if (changes['active']) {
          this.postBody = {
            ...this.postBody,
            active: Boolean(parseInt(changes['active']))
          };
        }
      });
  }

  addGroup() {
    if (
      this.postBody?.hasOwnProperty('name') &&
      this.postBody?.hasOwnProperty('active')
    ) {
      const currentGroupsAndPostGroup$ = combineLatest([
        this.zpxApiService.postGroup(this.postBody).pipe(first()),
        this.appService.groupsByNameAsc$.pipe(first())
      ]).pipe(takeUntil(this.onDestroy$));
      currentGroupsAndPostGroup$.subscribe(([newGroup, currentGroups]) => {
        newGroup.isClicked = true;
        currentGroups = currentGroups.map((g) => {
          return {
            ...g,
            isClicked: false
          };
        });
        currentGroups.push(newGroup);

        currentGroups = currentGroups.slice().sort((a, b) => {
          const _a = a.name.toLowerCase();
          const _b = b.name.toLowerCase();
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        });

        this.appService.groupsByNameAsc$.next(currentGroups);
        this.appService.selectedGroup$.next(newGroup);
        this.dialogRef.close();
      });
    }
  }
}
