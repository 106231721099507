import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ValidationError } from '@components/modals/import-modal/import-passholders.model';

@Component({
  selector: 'app-import-error-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule],
  templateUrl: './import-error-table.component.html',
  styleUrls: ['./import-error-table.component.scss']
})
export class ImportErrorTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['csvRow', 'errorType', 'errorDetail'];
  dataSource = new MatTableDataSource<ValidationError>([]);
  @Input() importErrors: ValidationError[];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource.data = this.importErrors;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
