import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';

import { PendoService } from '@zonar-ui/analytics';
import { PermissionsService } from '@zonar-ui/auth';

import { appName } from '@environments/shared';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { DeviceDetectionService } from './services/device-detection/device-detection.service';
import { SidenavHeaderConfig } from '@config/sidenav.config';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { UserCompany } from '@app/models/user.company.model';
import { SelectedCompanyService } from './services/selected-company/selected-company.service';
import { DivisionModalService } from './services/division-modal/division-modal.service';
import { AppService } from './app.service';
import { FaviconsService } from '@zonar-ui/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  loading = true;
  title = appName;

  currentCompany$ = new BehaviorSubject<UserCompany>({
    title: '',
    value: ''
  });
  sidenavHeader = SidenavHeaderConfig;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    footerOffset: false
  };
  divisionId: string = null;
  toggleFilterBarDisplay = true;

  mobileViewClasses = {
    'mobile-view': this.device.isMobile ? true : false,
    hidden: false
  };

  constructor(
    private auth: AuthService,
    private datadog: DataDogService,
    private pendoService: PendoService,
    private permissionsService: PermissionsService,
    public device: DeviceDetectionService,
    private selectedCompanyService: SelectedCompanyService,
    private _cdRef: ChangeDetectorRef,
    private divisionModalService: DivisionModalService,
    private appService: AppService,
    private faviconService: FaviconsService
  ) {
    this.auth.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => Boolean(isAuthenticated)),
        take(1)
      )
      .subscribe(() => {
        this.datadog.startSessionReplayRecording();
      });
  }

  ngOnInit(): void {
    this.divisionModalService.openDivisionModal();
    // when we initialize pendo, some of the api calls require a zoner owner id, which can only be attached to the
    // request after the permissions are loaded. therefore, we must wait to initialize pendo until after the
    // permissions are loaded. ultimately, this logic should be handled in the pendo service itself as described here:
    // https://jira.zonarsystems.net/browse/ZTT-1015
    this.permissionsService
      .getPermissions()
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(250),
        filter((permissions) => Boolean(permissions)),
        take(1)
      )
      .subscribe(() => {
        this.pendoService.initialize();
      });
  }

  ngOnDestroy(): void {
    this.datadog.stopSessionReplayRecording();
  }

  switchCompany(incomingCompany): void {
    // This is needed until fix is applied to sidenav component.
    this.permissionsService
      .getCompanyMap()
      .pipe(
        take(1),
        map((companies) => {
          if (companies && companies[incomingCompany.value]) {
            // Setting Company context to use it on Zonar-Owner-Id for selecting right profile.
            this.permissionsService.setCurrentCompanyContext(
              companies[incomingCompany.value]
            );
          }
          this.selectedCompanyService.setCompanyIdFromSideNav(incomingCompany);
          return incomingCompany;
        }),
        switchMap((incomingCompany) =>
          this.permissionsService.getUserProfileByCompanyId(incomingCompany)
        ),
        map((userProfile) => {
          if (userProfile.divisions.length === 1) {
            this.appService.selectedDivisionId$.next(userProfile.divisions[0]);
          }
        })
      )
      .subscribe();
  }

  onSideNavMobileMenuButtonToggled(event): void {
    this.sidenavParams.mobileOpened = event;
    this._cdRef.detectChanges();
  }
}
