import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { ImportPreviewTableComponent } from '@components/modals/import-modal/import-preview-table/import-preview-table.component';
import { ImportPassholdersService } from '@services/import-passholders/import-passholders.service';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ImportErrorTableComponent } from '@components/modals/import-modal/import-error-table/import-error-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ValidationError,
  ValidationState
} from '@components/modals/import-modal/import-passholders.model';

@Component({
  selector: 'app-import-preview',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    ImportPreviewTableComponent,
    ImportErrorTableComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './import-preview.component.html',
  styleUrls: ['./import-preview.component.scss']
})
export class ImportPreviewComponent implements OnInit, OnDestroy {
  @Output() cancel = new EventEmitter();

  constructor(public importPassholdersService: ImportPassholdersService) {}

  private unsubscribe$ = new Subject<void>();

  importErrors: ValidationError[];
  validImport: boolean;
  updateCount: number;
  newCount: number;
  unchangedCount: number;
  validationState: ValidationState;
  saveInProgress = false;

  ngOnInit(): void {
    combineLatest([
      this.importPassholdersService.frontendValidationState,
      this.importPassholdersService.backendValidationState
    ])
      .pipe(
        filter((valsArray) => valsArray.some((v) => v !== undefined)),
        map(([feValState, beValState]) => {
          this.validImport = true;
          if (feValState && !feValState.valid) {
            this.importErrors = feValState.errors;
            this.validImport = false;
            return;
          }
          if (beValState && !beValState.valid) {
            this.importErrors = beValState.errors;
            this.validImport = false;
            return;
          }
          if (beValState && beValState.valid) {
            this.updateCount = beValState.updates.length;
            this.newCount = beValState.new.length;
            this.unchangedCount = beValState.unchanged.length;
            this.validationState = beValState;
            return;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.importPassholdersService.resetValidationState();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  goBack() {
    this.cancel.emit();
  }

  saveImport() {
    this.saveInProgress = true;
    this.importPassholdersService
      .commitImportedPassholders(this.validationState.validationId)
      .pipe(
        tap(() => {
          this.saveInProgress = false;
        }),
        take(1)
      )
      .subscribe();
  }
}
