//Angular
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// 3rd Party dependencies
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

//Zonar Pattern Library
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarOwnerIdInterceptor } from '@zonar-ui/auth';
import { SidenavModule } from '@zonar-ui/sidenav';
import { ZonarUiI18nModule } from '@zonar-ui/i18n';
import { ZonarUiFooterModule } from '@zonar-ui/footer';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';

// App-level Imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { CoreModule } from '@app/modules/core/core.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      audience: environment.auth.audience,
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      max_age: 36000,
      useRefreshTokens: environment.auth.useRefreshTokens,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.appUrl}/*`,
          `${environment.apiBase.url}/*`,
          `${environment.coreEntityApiBase.url}/*`,
          `${environment.coreEntityApiBase.url}beta2/*`
        ]
      }
    }),
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    SidenavModule.forRoot({ stage: environment.authEnv || null }),
    ZonarUICoreModule,
    ZonarUiI18nModule,
    ZonarUiFooterModule,
    ZonarUiProgressSpinnerModule,
    MatTabsModule
  ],
  providers: [
    { provide: 'region', useValue: environment.region },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ZonarOwnerIdInterceptor,
      multi: true
    },
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: true },
    { provide: 'logoutReturnToUrl', useValue: window.location.origin }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
