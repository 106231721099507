import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  ImportPassholderPreviewRecord,
  PreviewType,
  ValidationState
} from '@components/modals/import-modal/import-passholders.model';

@Component({
  selector: 'app-import-preview-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule],
  templateUrl: './import-preview-table.component.html',
  styleUrls: ['./import-preview-table.component.scss']
})
export class ImportPreviewTableComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<ImportPassholderPreviewRecord> =
    new MatTableDataSource([]);
  displayedColumns: string[];

  @Input() validationState: ValidationState;
  @Input() previewType: PreviewType;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  noDataMessage = {
    update: 'No updated passholders',
    new: 'No new passholders',
    unchanged: 'No unchanged passholders'
  };

  ngOnInit(): void {
    this.generatePreviewData(this.previewType);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  generatePreviewData(previewType: PreviewType) {
    this.displayedColumns = this.validationState.importHeaders;
    let records: ImportPassholderPreviewRecord[];
    switch (previewType) {
      case PreviewType.UPDATE:
        records = this.validationState.updates;
        break;
      case PreviewType.NEW:
        records = this.validationState.new;
        break;
      case PreviewType.UNCHANGED:
        records = this.validationState.unchanged;
        break;
    }

    this.dataSource.data = records;
  }
}
