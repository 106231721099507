import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

import { FilterToggleComponent } from '@components/filter-toggle/filter-toggle.component';
import { PassholderTableComponent } from '@src/app/components/passholder-table/passholder-table.component';
import { TablePassholdersFilterBarComponent } from '@src/app/components/table-passholders-filter-bar/table-passholders-filter-bar.component';

import { DeviceDetectionService } from '@services/device-detection/device-detection.service';

@Component({
  selector: 'app-manage-passholders',
  standalone: true,
  imports: [
    CommonModule,
    FilterToggleComponent,
    PassholderTableComponent,
    TablePassholdersFilterBarComponent,
    MatTabsModule
  ],
  templateUrl: './manage-passholders.component.html',
  styleUrls: ['./manage-passholders.component.scss']
})
export class ManagePassholdersComponent {
  toggleFilterBarDisplay = true;

  mobileViewClasses = {
    'mobile-view': this.device.isMobile ? true : false,
    hidden: false
  };

  constructor(public device: DeviceDetectionService) {}

  mobileDisplayHandler(filtersDisplayed: boolean) {
    this.mobileViewClasses.hidden = filtersDisplayed ? true : false;
  }

  onPassholdersMobileFilterToggled(event): void {
    this.toggleFilterBarDisplay = !this.toggleFilterBarDisplay;
  }
}
