<table mat-table [dataSource]="dataSource">
  <!-- Date Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let historyRecord">{{historyRecord.date}}</td>
  </ng-container>

  <!-- Time Column -->
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let historyRecord">{{historyRecord.time}}</td>
  </ng-container>

  <!-- Edited By Column -->
  <ng-container matColumnDef="editedBy">
    <th mat-header-cell *matHeaderCellDef>Edited By</th>
    <td mat-cell *matCellDef="let historyRecord">{{historyRecord.editedBy}}</td>
  </ng-container>

  <!-- Edited Field Column -->
  <ng-container matColumnDef="editedField">
    <th mat-header-cell *matHeaderCellDef>Edited Field</th>
    <td mat-cell *matCellDef="let historyRecord">
      {{historyRecord.editedField}}
    </td>
  </ng-container>

  <!-- Old Value Column -->
  <ng-container matColumnDef="oldValue">
    <th mat-header-cell *matHeaderCellDef>Old Value</th>
    <td mat-cell *matCellDef="let historyRecord">{{historyRecord.oldValue}}</td>
  </ng-container>

  <!-- New Value Column -->
  <ng-container matColumnDef="newValue">
    <th mat-header-cell *matHeaderCellDef>New Value</th>
    <td mat-cell *matCellDef="let historyRecord">{{historyRecord.newValue}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      No Cardholder Edits Found
    </td>
  </tr>
</table>

<mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 500]" showFirstLastButtons>
</mat-paginator>
